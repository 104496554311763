@font-face {
  font-family: evermore;
  src: url(./media/fuentes/Evermore.otf);
}
@font-face {
  font-family: couture;
  src: url(./media/fuentes/couture.otf);
}


* {
  margin: 0;
  padding: 0;
  background-color: rgb(3, 3, 3);
}

.App {
  text-align: center;
  height: 100vmin;
  display: flex;
  justify-content: center;
  align-items: center;

}

.App .info {
  color: #fff;
  text-align: left;
  margin-right: 60px;
  
}
.App .info h1 { font-family: couture; font-size: 60px;}

.my { display: block;}

.my .img {

  height: 300px;
  

}

.my .status {
  height: 60px;
  transform: translateX(-86px) translateY(-18px);
  

}

.dd {
  font-family: couture;
  font-size: 30px;
  
  color: #ffffff;
  position: fixed;
  top: 600px;
}

@media only screen and (max-width:768px){
  .App {
    text-align: center;
    height: 100vmin;
    display: block;
  
  }

  .App .info {
    color: #fff;
    text-align: center;
    padding: 60px;
    margin-right: 0px;
    
  }
  .my { 
    transform: translate(30px);
  }

  .dd {
    transform: translateX(-140px);
  }
  
}